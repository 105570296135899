import { RouterState } from 'connected-react-router'
import { UserInfo, ReplyToChat, FetchingStates } from './thread'
import { MessageType, ThreadType, UndeliveredChat, UserType } from './messages'
import { Note } from './notes'
import { SingleBookingDetailType } from './bookings'
import { PropertyDetails, PropertyUploadType } from './properties'
import { DocumentLinks } from './documents'
import { UserBio } from './users'
import { UserPackages } from './packages'

export enum Status {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type AuthState = {
  currentUser?: UserType
  loginFailed: boolean
  token?: string
  status?: string
  userType?: string
}

export type MessageState = {
  messages: MessageType[]
  isFetchingMessages: boolean
  toggleFavoriteInProgress: boolean
  searchMessages?: MessageType[]
  searchMessageRequest: boolean
  currentPage: number
  lastPage: number
  fetchMoreMessagesRequested: boolean
  searchMessagesCurrentPage: number
  searchMessagesLastPage: number
  showFavoritedMessages: boolean
  mobileSidebarOpen: boolean
}

export type ThreadState = {
  threads: ThreadType[]
  undeliveredChats: UndeliveredChat[]
  fetchMoreThreadsRequested: boolean
  user: UserInfo
  paidSuccess: boolean
  updatedPriority: boolean
  selectedForReplyChat: ReplyToChat
  fetchParentMessage: FetchingStates
  fetchWholeThread: FetchingStates
  documentFetching?: boolean
  servicesStatus?: undefined | 'success' | 'error'
  sendDocument: FetchingStates
  contentStatus?: string
  actionedChatStatus?: keyof typeof Status
}

export type NoteState = {
  activeUserId: string
  notes: Note[]
  currentPage: number
  lastPage: number
  notesUpdationRequested: boolean
  fetchMoreNotesFailed: boolean
  fetchNotesRequested: boolean
  fetchMoreNotesRequested: boolean
  failedNoteApi: boolean
  fetchNotesFailed: boolean
}

export type BookingState = {
  addBookingsRequested: boolean
  cancelBookingsRequested: boolean
  editBookingsRequested: boolean
  fetchBookingsRequested: boolean
  fetchMoreBookingsRequested: boolean
  bookings: SingleBookingDetailType[]
  status?: string
  currentPage: number
  lastPage: number
}

export type PropertyState = {
  recommendPropertyRequested: boolean
  unlistPropertyRequested: boolean
  properties: PropertyDetails[]
  status?: string
  propertiesToUpload?: PropertyUploadType[]
  propertiesToUploadStatus?: string
}

export type DocumentState = {
  storedUserId: string
  fetchDocumentsRequested: boolean
  updateDocumentsRequested: boolean
  documents?: DocumentLinks
  status?: string
}

export type UsersState = {
  requested: boolean
  success: boolean
  failure: boolean
  upsertRequested: boolean
  upsertFailure: boolean
  users: UserBio[]
}

export type PackageState = {
  requested: boolean
  success: boolean
  failure: boolean
  updateRequested: boolean
  updateFailure: boolean
  updateSuccess: boolean
  packages: UserPackages
}

export type RootState = {
  authState: AuthState
  messageState: MessageState
  threadState: ThreadState
  noteState: NoteState
  bookingState: BookingState
  propertyState: PropertyState
  documentState: DocumentState
  usersState: UsersState
  router: RouterState
  feedback: FeedbackState
  packageState: PackageState
}

export type PayloadType = {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}

export type PriorityType = {
  countries: string[]
  paid: boolean
  unpaid: boolean
  vip: boolean
  high: boolean
  medium: boolean
  low: boolean
}

export type FiltersType = {
  destination?: string[]
  has_paid?: boolean[]
  priority?: string[]
}

export type FeedbackState = {
  title: string
  show: boolean
  severity: 'error' | 'info' | 'success' | 'warning'
  position: {
    horizontal: 'center' | 'left' | 'right'
    vertical: 'bottom' | 'top'
  }
  autoHideDuration: number
}
