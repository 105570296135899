import { MouseEvent, ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

import { DeleteChat, ParentChatBubble } from '..'
import { SET_REPLY_MESSAGE_DETAILS } from '../../redux/constants'
import { ChatStatus, TextChat } from '../../types/messages'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0',
    display: 'flex'
  },
  msgContainer: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    }
  },
  paper: {
    borderRadius: '20px 20px 20px 0',
    boxSizing: 'border-box',
    padding: '15px 20px',
    position: 'relative',
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        '& $messageOptions': {
          visibility: 'visible'
        }
      }
    }
  },
  messageOptions: {
    color: 'grey',
    visibility: 'visible',
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden'
    },
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  chat: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  msgTime: {
    fontSize: '0.6em'
  },
  doubleClick: {
    flex: 1
  }
}))

export function TenantSimpleChat(info: TextChat): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { chat, name, created_at, chat_id, parent_chat, sender_name, status } = info

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleReplyToMsg = () => {
    setAnchorEl(null)
    dispatch({
      type: SET_REPLY_MESSAGE_DETAILS,
      payload: {
        sender: sender_name || name,
        chat,
        chatId: chat_id
      }
    })
  }

  const doubleClickHandle = () => {
    if (status === ChatStatus.ACTIVE) handleReplyToMsg()
  }

  return (
    <div className={classes.container} id={chat_id} data-testid="tenant-simple-chat">
      <div className={classes.msgContainer}>
        <Paper elevation={2} className={classes.paper}>
          {status === ChatStatus.ACTIVE ? (
            <>
              {parent_chat && (
                <ParentChatBubble sender={parent_chat.name} chat={parent_chat.chat} chatId={parent_chat.chat_id} />
              )}
              <Typography variant="subtitle2" color="primary">
                {name}
              </Typography>
              <IconButton aria-label="message-options" classes={{ root: classes.messageOptions }} onClick={handleClick}>
                <KeyboardArrowDown />
              </IconButton>
              <Menu
                data-testid="menu"
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleReplyToMsg}>Reply</MenuItem>
              </Menu>
              <Typography variant="body1" className={classes.chat}>
                {chat}
              </Typography>
            </>
          ) : (
            <DeleteChat type="tenant" />
          )}
        </Paper>
        <Typography variant="subtitle2" color="textSecondary" className={classes.msgTime}>
          {moment.utc(created_at).local().format('dddd, Do of MMMM, H:mm')}
        </Typography>
      </div>
      <div className={classes.doubleClick} onDoubleClick={doubleClickHandle} />
    </div>
  )
}
