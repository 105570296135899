import { ReactElement, useState, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Fab, Icon, IconButton, TextareaAutosize } from '@material-ui/core'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import { Close, House, Send, LibraryAdd, AttachFile, AddPhotoAlternate } from '@material-ui/icons'
import moment from 'moment'

import { ChatStatus } from '../../types/messages'
import type { NewChat } from '../../types/messages'
import { getAuthState, getThreadState } from '../../redux/selectors'
import { CLEAR_REPLY_MESSAGE_DETAILS, PARTIAL_CLEAR_PROPERTY_STATE, SEND_NEW_CHAT_REQUEST } from '../../redux/constants'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { ParentChatBubble, RecommendPropertyModal } from '..'
import { MultiplePropertyUpload } from '../MultiplePropertyUpload'
import { ChatFileUploadDialog } from '../ChatFileUploadDialog'
import { SendContent } from '../SendContent'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../hooks/featureFlags/types'
import { useAnalytics, EventName } from '../../analytics'

enum Action {
  RECOMMEND_PROPERTY,
  RECOMMEND_MULTIPLE_PROPERTY,
  UPLOAD_FILE,
  SEND_CONTENT
}

const actions = (
  isSendContentEnabled: boolean,
  isRecommendSinglePropertyEnabled: boolean,
  isUploadDocumentEnabled: boolean
) => [
  ...(isRecommendSinglePropertyEnabled
    ? [{ icon: <House />, action: Action.RECOMMEND_PROPERTY, name: 'Recommend property' }]
    : []),
  {
    icon: <LibraryAdd />,
    action: Action.RECOMMEND_MULTIPLE_PROPERTY,
    name: 'Recommend multiple properties'
  },
  ...(isUploadDocumentEnabled ? [{ icon: <AttachFile />, action: Action.UPLOAD_FILE, name: 'Upload file' }] : []),
  ...(isSendContentEnabled ? [{ icon: <AddPhotoAlternate />, action: Action.SEND_CONTENT, name: 'Send content' }] : [])
]

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    padding: '10px',
    position: 'relative',
    backgroundColor: '#F0F0F0'
  },
  replyContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  closeBtn: {
    height: 'fit-content',
    marginLeft: 15
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  speedDialBtn: {
    position: 'absolute',
    left: 8,
    bottom: 10
  },
  messageInputWrapper: {
    display: 'flex',
    margin: '0 10px',
    width: 'calc(100% - 170px)',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 130px)'
    }
  },
  messageInput: {
    width: '100%',
    padding: '17px 10px 3px 10px',
    resize: 'none',
    fontFamily: 'Roboto',
    fontSize: 16,
    borderRadius: 5
  },
  sendBtn: {
    height: 60,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileSendBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      marginLeft: 12
    }
  }
}))

export function ThreadInput(): ReactElement {
  const classes = useStyles()
  const { selectedForReplyChat } = useSelector(getThreadState)
  const dispatch = useDispatch()

  const { track } = useAnalytics()
  const { currentUser } = useSelector(getAuthState)
  const { messageId, thread } = useInfoByMessageId()

  const sendMsgInputRef = useRef<HTMLTextAreaElement>(null)
  const [chat, setChat] = useState('')
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [currentAction, setCurrentAction] = useState<Action>(Action.RECOMMEND_PROPERTY)

  const { sender: parentSender, chat: parentChat, chatId: parentChatId } = selectedForReplyChat

  const isSendContentEnabled = useFeatureToggle(FeatureFlag.CREATE_CONTENT_CARDS)
  const isRecommendSinglePropertyEnabled = useFeatureToggle(FeatureFlag.RECOMMEND_SINGLE_PROPERTY)
  const isUploadDocumentEnabled = useFeatureToggle(FeatureFlag.RECOMMEND_SINGLE_PROPERTY)

  useLayoutEffect(() => {
    if (sendMsgInputRef.current) {
      sendMsgInputRef.current.focus()
    }
    // subscribe to when thread changes and when clicked on reply chat or close parent chat bubble
  }, [messageId, parentChatId])

  const toggleSpeedDial = () => setSpeedDialOpen((open) => !open)

  const sendMessage = () => {
    if (!chat || !currentUser) return

    // Track response time to mover messages
    const lastMessage = thread?.chats[0]
    if (lastMessage) {
      const { sender_user_type, created_at } = lastMessage
      if (sender_user_type === 'tenant') {
        track({
          name: EventName.TimedEvent,
          eventId: 'chat_reply_time',
          elapsedTime: moment().diff(moment(created_at), 'minutes')
        })
      }
    }
    const newChat: NewChat = {
      chat: chat,
      created_at: moment.utc().toISOString(),
      name: currentUser.name,
      sender_name: currentUser.name,
      sender_user_type: currentUser.user_type as string,
      parent_id: parentChatId || undefined,
      parent_chat: parentChatId
        ? {
            chat_id: parentChatId,
            sender_name: parentSender,
            chat: parentChat
          }
        : undefined,
      status: ChatStatus.ACTIVE,
      user: {
        name: currentUser.name,
        user_id: currentUser.user_id
      }
    }
    dispatch({ type: SEND_NEW_CHAT_REQUEST, payload: { chat: newChat } })
    setChat('')
    dispatch({ type: CLEAR_REPLY_MESSAGE_DETAILS })
  }

  const handleOnClickAction = (action: Action) => {
    if (action === Action.RECOMMEND_PROPERTY) dispatch({ type: PARTIAL_CLEAR_PROPERTY_STATE })

    setCurrentAction(action)
    setShowDialog(true)
    setSpeedDialOpen(false)
  }

  return (
    <div data-testid="thread-input-container" className={classes.container}>
      {parentChatId && (
        <div className={classes.replyContainer}>
          <ParentChatBubble sender={parentSender} chat={parentChat} chatId={parentChatId} />
          <IconButton
            data-testid="close-icon"
            className={classes.closeBtn}
            onClick={() => dispatch({ type: CLEAR_REPLY_MESSAGE_DETAILS })}
          >
            <Close />
          </IconButton>
        </div>
      )}
      <SpeedDial
        ariaLabel="speed-dial"
        data-testid="speed-dial"
        open={speedDialOpen}
        onOpen={toggleSpeedDial}
        onClose={toggleSpeedDial}
        icon={<SpeedDialIcon />}
        direction={'up'}
        classes={{ root: classes.speedDialBtn }}
      >
        {actions(isSendContentEnabled, isRecommendSinglePropertyEnabled, isUploadDocumentEnabled).map(
          ({ name, icon, action }) => (
            <SpeedDialAction
              data-testid={name}
              key={name}
              icon={icon}
              tooltipTitle={name}
              onClick={() => handleOnClickAction(action)}
            />
          )
        )}
      </SpeedDial>
      <div className={classes.inputContainer}>
        <div className={classes.messageInputWrapper}>
          <TextareaAutosize
            data-testid="new-message-input"
            className={classes.messageInput}
            value={chat}
            ref={sendMsgInputRef}
            onChange={(e) => setChat(e.target.value)}
            placeholder="Type your message..."
            minRows={2}
            maxRows={10}
            data-track-submit="send-new-chat"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!e.shiftKey) {
                  sendMessage()
                  e.preventDefault()
                }
              }
            }}
          />
        </div>
        <Button
          data-testid="send-message-btn"
          variant="contained"
          color="primary"
          className={classes.sendBtn}
          endIcon={<Icon>send</Icon>}
          onClick={sendMessage}
          disabled={chat.length === 0}
        >
          Send
        </Button>
        <Fab
          data-testid="send-button-mob"
          aria-label="send"
          size="medium"
          color="primary"
          className={classes.mobileSendBtn}
          onClick={sendMessage}
          data-track-click="send-new-chat"
        >
          <Send />
        </Fab>
      </div>
      {showDialog && (
        <>
          {currentAction === Action.RECOMMEND_PROPERTY && (
            <RecommendPropertyModal open={showDialog} onClose={() => setShowDialog(false)} />
          )}

          {currentAction === Action.RECOMMEND_MULTIPLE_PROPERTY && (
            <MultiplePropertyUpload open={showDialog} onClose={() => setShowDialog(false)} />
          )}

          {currentAction === Action.UPLOAD_FILE && (
            <ChatFileUploadDialog open={showDialog} onClose={() => setShowDialog(false)} />
          )}

          {currentAction === Action.SEND_CONTENT && (
            <SendContent open={showDialog} onClose={() => setShowDialog(false)} />
          )}
        </>
      )}
    </div>
  )
}
