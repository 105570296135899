const host = window.location.hostname

export const supportUserId = 'ewmlGV20eW7ee5sFPsNT'

export const supportUserName = 'Perchpeek Support'

export const pusherKey =
  host.includes('localhost') || host.includes('support-develop') || host.includes('staging')
    ? 'aba5583de39871759fe3'
    : '63aee0dc34cf9d2dabe6'

export const webUrl = /localhost|develop|staging/g.test(host)
  ? 'https://staging.perchpeek.com'
  : 'https://www.perchpeek.com'

const PROD_REGEXP = 'support-netilify-develop|-production|(support|ops).perchpeek'

const urlMapping: Record<string, string> = {
  localhost: 'https://ops-api-develop.perchpeek.com/api',
  '-staging': 'https://api-stage.perchpeek.com/ops',
  [PROD_REGEXP]: 'https://api-versioned.perchpeek.com/ops'
}

const [, apiUrl] = Object.entries(urlMapping).find(([key]) => new RegExp(key, 'g').test(host)) || []

export const isProduction = new RegExp(PROD_REGEXP, 'g').test(host)

export default apiUrl
