import { ReactElement, UIEvent, useEffect, useState } from 'react'
import moment from 'moment'
import throttle from 'lodash/throttle'
import InfiniteScroll from 'react-infinite-scroller'
import { Dialog, Fab, Zoom } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { Chat, Loader, SidebarDrawer, ThreadHeader, ThreadInput, UserInfoModal } from '../../components'
import {
  CLEAR_PROPERTIES,
  FETCH_MORE_THREADS_REQUEST,
  FETCH_THREADS_REQUEST,
  SHOW_FEEDBACK
} from '../../redux/constants'
import { scrollTo } from '../../utils/string'
import { getPropertyState, getThreadState } from '../../redux/selectors'
import { usePusherForThread } from '../../hooks/usePusher'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { useViewMode, ViewMode } from '../../hooks/useViewMode'
import { ChatStatus } from '../../types/messages'

type StyleProps = { mobileView: boolean }

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 55px)',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  messagingContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: 100
  },
  chatsContainer: {
    position: 'relative',
    flex: 1,
    boxSizing: 'border-box',
    padding: '0 10px',
    overflow: 'hidden',
    overflowY: 'scroll',
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0
    }
  },
  scrollToBtn: {
    position: 'fixed',
    bottom: ({ mobileView }: StyleProps) => (mobileView ? 90 : 110),
    right: ({ mobileView }: StyleProps) => (mobileView ? 10 : 40),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#F3F3F3'
    }
  },
  scrollToTopBtn: {
    bottom: ({ mobileView }: StyleProps) => (mobileView ? 150 : 170)
  },
  msgNotiIcon: {
    height: 12,
    width: 12,
    position: 'absolute',
    top: 2,
    right: 4,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      top: 6,
      right: 4
    }
  },
  loader: {
    width: 200,
    height: 200
  }
}))

export const Thread = (): ReactElement => {
  const dispatch = useDispatch()
  const viewMode = useViewMode()
  const mobileView = viewMode === ViewMode.MOBILE
  const classes = useStyles({ mobileView })
  const { fetchMoreThreadsRequested, fetchParentMessage, fetchWholeThread, threads, sendDocument } =
    useSelector(getThreadState)
  const { status: propertyStateStatus } = useSelector(getPropertyState)
  const { messageId, thread, customer, sortedChats } = useInfoByMessageId()

  const [showInfo, setShowInfo] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showScrollButton, setShowScrollButton] = useState(false)
  const [showNewMsgNotification, setShowNewMsgNotification] = useState(false)

  useEffect(() => {
    if (thread) {
      const { pending, chats } = thread

      if (pending.length !== 0 || fetchWholeThread.success) {
        scrollTo('bottom-of-thread')
        return
      }

      if (chats[0].sender_user_type === 'tenant') {
        showScrollButton ? setShowNewMsgNotification(true) : scrollTo('bottom-of-thread')
        return
      }

      scrollTo('bottom-of-thread')
    }
  }, [messageId, threads.length, thread?.pending.length, thread?.chats[0]?.chat_id, fetchWholeThread.success])

  useEffect(() => {
    dispatch({ type: FETCH_THREADS_REQUEST, payload: { messageId } })

    return () => {
      // Clears all properties from state on unmount so that next time <Thread /> mounts
      // all properties are re-fetched (see ThreadPropertyCard) with their latest data
      dispatch({ type: CLEAR_PROPERTIES })
    }
  }, [messageId])

  const pusherChannelMembers = usePusherForThread()

  const fetchMore = () => {
    if (!fetchMoreThreadsRequested) {
      dispatch({ type: FETCH_MORE_THREADS_REQUEST, payload: { currentPage: thread?.currentPage, messageId } })
    }
  }

  useEffect(() => {
    if (propertyStateStatus === 'Property Unlisted') {
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: 'Property Unlisted', severity: 'success' }
      })
    } else if (propertyStateStatus === 'Property Unlisting Failed') {
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: 'Failed! Try Again.', severity: 'error' }
      })
    }
  }, [propertyStateStatus])

  const handleScroll = (event: UIEvent) => {
    // Subtract the scrolled height from the total scrollable height.
    // If this is less than or equal to the visible area, you've almost reached the bottom!
    const target = event.target as HTMLDivElement
    const top = target.scrollTop <= 0
    const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 100

    if (Boolean(top) || Boolean(bottom) === Boolean(showScrollButton)) {
      setShowScrollButton((show) => !show)

      if (bottom) {
        setShowNewMsgNotification(false)
      }
    }
  }

  const showInfoModal = () => {
    history.pushState({}, '', `/messages/${messageId}/info`)
    setShowInfo(true)
  }

  const closeInfoModal = () => {
    history.pushState({}, '', `/messages/${messageId}`)
    setShowInfo(false)
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  if (!thread) return <Loader />

  return (
    <div data-testid="thread-container" className={classes.container}>
      <div className={classes.messagingContainer}>
        <ThreadHeader message={thread.message} showInfoModal={showInfoModal} toggleDrawer={toggleDrawer} />
        <div
          className={classes.chatsContainer}
          data-testid="chat-list-container"
          onScroll={throttle(handleScroll, 500)}
        >
          <div id="top-of-thread" />

          <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchMore()}
            hasMore={thread.currentPage < thread.lastPage}
            initialLoad={false}
            isReverse={true}
            element={'ul'}
            useWindow={false}
          >
            {sortedChats &&
              sortedChats.map((chat, index) => {
                const isChatRead =
                  (moment(customer?.updated_at) > moment(chat.created_at) || Boolean(pusherChannelMembers.length)) &&
                  chat.status !== ChatStatus.PENDING
                return <Chat key={index} read={isChatRead} {...chat} />
              })}
          </InfiniteScroll>

          <Zoom in={showScrollButton}>
            <Fab
              aria-label="scroll-to-top-button"
              size={mobileView ? 'small' : 'medium'}
              className={`${classes.scrollToBtn} ${classes.scrollToTopBtn}`}
              onClick={() => scrollTo('top-of-thread')}
            >
              <KeyboardArrowUp />
            </Fab>
          </Zoom>

          <Zoom in={showScrollButton}>
            <Fab
              aria-label="scroll-to-bottom-button"
              size={mobileView ? 'small' : 'medium'}
              className={classes.scrollToBtn}
              onClick={() => {
                scrollTo('bottom-of-thread')
                setShowNewMsgNotification(false)
              }}
            >
              <KeyboardArrowDown />
              {showNewMsgNotification && <div data-testid="Msg-Notification-icon" className={classes.msgNotiIcon} />}
            </Fab>
          </Zoom>

          <div id="bottom-of-thread" />
        </div>
        <ThreadInput />
      </div>

      <SidebarDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <UserInfoModal open={showInfo} onClose={closeInfoModal} />

      <Dialog
        open={fetchParentMessage.requested || fetchWholeThread.requested || sendDocument.requested}
        data-testid="loading-parent-message-dialog"
      >
        <div className={classes.loader}>
          <Loader ariaLabel="Loading thread" />
        </div>
      </Dialog>
    </div>
  )
}
