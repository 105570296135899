import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { LOGOUT_USER_REQUEST } from '../../redux/constants'
import perchpeekLogo from '../../assets/logo-white.png'
import { HeaderNavButton } from '..'
import { getAuthState, getThreadState } from '../../redux/selectors'
import { UserRoles } from '../../types/messages'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '50px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  contentWrapper: {
    maxWidth: '1600px',
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: '22px',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

export function Header(): ReactElement {
  const classes = useStyles()
  const route = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentUser } = useSelector(getAuthState)
  const {
    user: {
      info: { linked_user_id }
    }
  } = useSelector(getThreadState)

  const isMessagesRoute = route.path.includes('/messages')
  const isUsersRoute = route.path.includes('/users')
  const isManageRoute = route.path.includes('/manage')
  const isCustomerSuccessLead = currentUser && currentUser.role?.slug === UserRoles.CUSTOMER_SUCCESS_LEAD

  const onLogOut = () => {
    dispatch({ type: LOGOUT_USER_REQUEST })
    localStorage.clear()
    history.push('/login')
  }

  return (
    <header className={classes.container} data-testid="header-container">
      <div className={classes.contentWrapper}>
        <img src={perchpeekLogo} className={classes.logo} alt="PerchPeek" data-testid="logo-icon" />
        <HeaderNavButton
          label="Messages"
          active={isMessagesRoute}
          handler={() => {
            const activeMoverMessageId = localStorage.getItem('activeMoverMessageId')
            const messagesRoute =
              activeMoverMessageId && isManageRoute ? `/messages/${JSON.parse(activeMoverMessageId)}` : '/messages'

            !isMessagesRoute && history.push(messagesRoute)
          }}
        />
        {isCustomerSuccessLead && (
          <HeaderNavButton
            label="Users and Roles"
            active={isUsersRoute}
            handler={() => !isUsersRoute && history.push('/users')}
          />
        )}
        <HeaderNavButton
          label="Manage"
          active={isManageRoute}
          handler={() =>
            !isManageRoute && isMessagesRoute
              ? history.push(`/manage?v2Url=/feed/${linked_user_id}`)
              : history.push('/manage')
          }
        />
        <div style={{ marginLeft: 'auto' }}>
          <HeaderNavButton noPadding label="Help" handler={window.openZendeskWidget} startIcon={<HelpOutlineIcon />} />
          <HeaderNavButton label="Log Out" handler={onLogOut} />
        </div>
      </div>
    </header>
  )
}
