import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { getAuthState } from '../../redux/selectors'

// eslint-disable-next-line
export function PrivateRoute({ component: Component, ...rest }: any): ReactElement {
  const { currentUser } = useSelector(getAuthState)
  return (
    <Route
      {...rest}
      render={(props) => (currentUser ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
    />
  )
}
