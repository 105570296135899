import { ReactElement, useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Box, IconButton, Paper, Typography, Drawer, Switch, Divider } from '@material-ui/core'
import { Close, FilterList } from '@material-ui/icons'
import { useDebounce } from '../../hooks/useDebounce'
import { Message, Loader } from '..'
import { getMessageState } from '../../redux/selectors'
import {
  CLEAR_SEARCH_RESULT_REQUEST,
  FETCH_MESSAGES_REQUEST,
  FETCH_MORE_MESSAGES_REQUEST,
  FETCH_MORE_FAVORITED_MESSAGES_REQUEST,
  FETCH_FAVORITED_MESSAGES_REQUEST,
  SEARCH_MESSAGES_REQUEST,
  TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES
} from '../../redux/constants'
import { MobileHeader } from '..'
import { getSelectedPriorities } from '../../utils/string'
import { MessageFilterModal } from '../MessageFilterModal'
import { PriorityType } from '../../types/state'
import { useViewMode, ViewMode } from '../../hooks/useViewMode'

type StyleProps = { showFavoritedMessages: boolean }

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: 'inherit',
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 300
    }
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 5
  },
  allUsersText: {
    color: ({ showFavoritedMessages }: StyleProps) => (!showFavoritedMessages ? 'unset' : 'rgb(175,175,175)')
  },
  favoritedUsersText: {
    color: ({ showFavoritedMessages }: StyleProps) => (showFavoritedMessages ? 'unset' : 'rgb(175,175,175)')
  },
  messagesContainer: {
    height: '100%',
    width: '100%'
  },
  searchBar: {
    margin: 0
  },
  emptyView: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 150
  },
  mobileSidebar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  messageDrawer: {
    height: '100%',
    width: 600,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  filterBadge: {
    height: 8,
    width: 8,
    position: 'absolute',
    top: 6,
    right: 4,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      top: 6,
      right: 4
    }
  },
  searchWrapper: {
    padding: '10px 10px 0 10px',
    background: 'white',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: 'calc(100% - 20px)',
      marginTop: 90,
      marginLeft: 10,
      padding: 0
    }
  },
  searchInputWrapper: {
    display: 'flex',
    margin: '15px 0'
  },
  searchLoader: {
    height: 400
  },
  contentWrapper: {
    height: 'calc(100% - 130px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      marginTop: 205,
      height: 'calc(100% - 205px)',
      paddingTop: 0
    }
  },
  filterBtn: {
    marginLeft: 5,
    position: 'relative'
  },
  container: {
    width: 500
  }
}))

export function MessagesSidebar(): ReactElement {
  const dispatch = useDispatch()
  const { messageId } = useParams<{ messageId: string }>()
  const viewMode = useViewMode()
  const mobileView = viewMode === ViewMode.MOBILE

  const {
    messages,
    searchMessages,
    searchMessageRequest,
    currentPage,
    lastPage,
    fetchMoreMessagesRequested,
    searchMessagesCurrentPage,
    searchMessagesLastPage,
    mobileSidebarOpen,
    showFavoritedMessages,
    isFetchingMessages
  } = useSelector(getMessageState)

  const [searchString, setSearchString] = useState('')
  const [showFilterModal, setShowFilterModal] = useState(false)

  const initialPriorityState = {
    countries: [],
    paid: false,
    unpaid: false,
    vip: false,
    high: false,
    medium: false,
    low: false
  }

  const [checkState, setCheckState] = useState<PriorityType>(initialPriorityState)
  const [showFiltersBadge, setShowFiltersBadge] = useState<boolean>(false)

  const debouncedSearchString = useDebounce(searchString, 500)

  const { countries, paid, unpaid, vip, high, medium, low } = checkState

  const classes = useStyles({ showFavoritedMessages })

  const sortedSearchMessages =
    searchMessages && searchMessages.sort((a, b) => (moment(a.updated_at).isBefore(b.updated_at) ? 1 : -1))

  const checkPrioritiesState = (condition: string) => {
    switch (condition) {
      case 'Any truthy':
        return paid || unpaid || vip || high || medium || low || countries.length > 0

      case 'All falsy':
        return !paid && !unpaid && !vip && !high && !medium && !low && countries.length === 0

      default:
        return false
    }
  }

  useEffect(() => {
    if (searchMessages && debouncedSearchString.length === 0 && checkPrioritiesState('All falsy')) {
      dispatch({ type: CLEAR_SEARCH_RESULT_REQUEST })
    }

    if (debouncedSearchString.length > 1) {
      makeSearchRequest(checkState)
    }
  }, [debouncedSearchString])

  const fetchMore = () => {
    if (!fetchMoreMessagesRequested) {
      const actionType = showFavoritedMessages ? FETCH_MORE_FAVORITED_MESSAGES_REQUEST : FETCH_MORE_MESSAGES_REQUEST
      dispatch({ type: actionType, payload: { currentPage } })
    }
  }

  const handleFavoritedMessagesToggle = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES })

    if (checkPrioritiesState('All falsy') && debouncedSearchString.length === 0) {
      dispatch({ type: checked ? FETCH_FAVORITED_MESSAGES_REQUEST : FETCH_MESSAGES_REQUEST })
    } else {
      confirmPriorities()
    }
  }

  const makeSearchRequest = (rawPrioritiesData: PriorityType) => {
    const priorities = getSelectedPriorities(rawPrioritiesData)

    dispatch({
      type: SEARCH_MESSAGES_REQUEST,
      payload: { searchString: debouncedSearchString, priorities, searchMessagesCurrentPage: 0 }
    })
  }

  /*
  If string exists in the search messages box and filters are applied, and then clear search is clicked...
  we check if any filters are already applied, if so, we need to show messages pertaining to the filters.
  In case otherwise, the if condition in the above useEffect handles that case due to debouncedSearchString as dependency
  */

  const clearSearch = (rawPrioritiesData: PriorityType) => {
    if (searchMessages && checkPrioritiesState('Any truthy')) {
      const priorities = getSelectedPriorities(rawPrioritiesData)

      dispatch({
        type: SEARCH_MESSAGES_REQUEST,
        payload: { searchString: '', priorities, searchMessagesCurrentPage: 0 }
      })
    }
  }

  /*
  When clear filters is clicked, we will check if any search string exists in the search messages box.
  If no text, that means we need all the messages.
  Else text is present, that means we need to show messages matching only the search string.
  */

  const clearFilters = () => {
    setShowFiltersBadge(false)
    setCheckState(initialPriorityState)

    if (debouncedSearchString.length < 1) {
      dispatch({ type: CLEAR_SEARCH_RESULT_REQUEST })
    }

    if (debouncedSearchString.length > 1) {
      makeSearchRequest(initialPriorityState)
    }
  }

  const confirmPriorities = (loadMore = false) => {
    if (checkPrioritiesState('Any truthy')) setShowFiltersBadge(true)

    const priorities = getSelectedPriorities(checkState)

    dispatch({
      type: SEARCH_MESSAGES_REQUEST,
      payload: {
        searchString,
        priorities,
        searchMessagesCurrentPage: loadMore ? searchMessagesCurrentPage : 0,
        loadMore
      }
    })
    setShowFilterModal(false)
  }

  const renderSidebarContent = () => {
    const deviceType = mobileView ? 'mobile' : 'desktop'

    return (
      <Paper className={classes.messagesContainer} square key={deviceType}>
        <MobileHeader title="Messages" />
        <div className={classes.searchWrapper}>
          <div className={classes.toggleContainer}>
            <Typography data-testid="all-users-text" variant="subtitle1" className={classes.allUsersText}>
              All users
            </Typography>
            <Switch
              disabled={isFetchingMessages}
              checked={showFavoritedMessages}
              onChange={(event) => handleFavoritedMessagesToggle(event)}
              name="favoritedUsersSwitch"
              inputProps={{ 'aria-label': 'FavoritedUsers' }}
            />
            <Typography data-testid="favorited-users-text" variant="subtitle1" className={classes.favoritedUsersText}>
              Starred users only
            </Typography>
          </div>
          <div>
            <Divider />
            <div className={classes.searchInputWrapper}>
              <TextField
                inputProps={{ 'data-testid': `${deviceType}-searchbar` }}
                className={classes.searchBar}
                variant="outlined"
                margin="dense"
                fullWidth
                label="Search messages"
                name="search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              {debouncedSearchString.length ? (
                <IconButton
                  data-testid={`${deviceType}-close-icon`}
                  size="small"
                  color="primary"
                  onClick={() => {
                    setSearchString('')
                    clearSearch(checkState)
                  }}
                >
                  <Close />
                </IconButton>
              ) : null}
              <div className={classes.filterBtn}>
                <IconButton
                  data-testid={`${deviceType}-filter-icon`}
                  size="small"
                  color={'primary'}
                  onClick={() => setShowFilterModal(true)}
                >
                  <FilterList />
                </IconButton>
                {showFiltersBadge && <div data-testid="filterBadge-icon" className={classes.filterBadge} />}
              </div>
            </div>
            <Divider />
          </div>
        </div>
        <div className={classes.contentWrapper}>
          {messages && !sortedSearchMessages && debouncedSearchString.length < 2 && (
            <InfiniteScroll
              data-testid={`${deviceType}-infinite-scroll`}
              pageStart={0}
              loadMore={() => fetchMore()}
              hasMore={currentPage < lastPage}
              element={'div'}
              initialLoad={false}
              useWindow={false}
            >
              {messages.map((item) => (
                <Message
                  key={item.message_id}
                  message={item}
                  deviceType={deviceType}
                  testId="normal"
                  selected={item.message_id === messageId}
                />
              ))}
            </InfiniteScroll>
          )}
          {sortedSearchMessages && (
            <InfiniteScroll
              data-testid={`${deviceType}-infinite-scroll-sorted-messages`}
              pageStart={0}
              loadMore={() => {
                confirmPriorities(true)
              }}
              hasMore={searchMessagesCurrentPage < searchMessagesLastPage}
              element={'div'}
              initialLoad={false}
              useWindow={false}
            >
              {sortedSearchMessages.map((item) => (
                <Message
                  key={item.message_id}
                  message={item}
                  deviceType={deviceType}
                  testId="searched"
                  selected={item.message_id === messageId}
                />
              ))}
            </InfiniteScroll>
          )}
          {(debouncedSearchString.length > 1 && searchMessageRequest) ||
            (isFetchingMessages && (
              <div className={classes.searchLoader}>
                <Loader />
              </div>
            ))}
          {sortedSearchMessages && sortedSearchMessages.length === 0 && (
            <div className={classes.emptyView} data-testid={`${deviceType}-empty-view`}>
              <Typography variant="subtitle1">No results found</Typography>
            </div>
          )}
        </div>
      </Paper>
    )
  }

  return (
    <Fragment>
      {mobileView ? (
        <div className={classes.mobileSidebar} data-testid="main-content">
          <Drawer
            variant="persistent"
            anchor="left"
            open={mobileSidebarOpen}
            classes={{
              paper: classes.messageDrawer
            }}
          >
            {renderSidebarContent()}
          </Drawer>
        </div>
      ) : (
        <Box className={classes.drawer} data-testid="main-content">
          {renderSidebarContent()}
        </Box>
      )}
      <MessageFilterModal
        showFilterModal={showFilterModal}
        closeFilterModal={() => setShowFilterModal(false)}
        checkState={checkState}
        setCheckState={setCheckState}
        confirmPriorities={confirmPriorities}
        clearFilters={clearFilters}
      />
    </Fragment>
  )
}
