import { ReactElement, useEffect, useState, MouseEvent } from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Divider, Button, IconButton, Chip, Typography, Menu, MenuItem } from '@material-ui/core'
import { Person, ArrowBack, MenuOutlined, MoreVert } from '@material-ui/icons'
import {
  FETCH_WHOLE_THREAD_REQUEST,
  MARK_USER_PAID_REQUEST,
  RESET_PAID_SUCCESS_STATUS,
  RESET_UPDATE_PRIORITY_SUCCESS_STATUS,
  SHOW_FEEDBACK,
  TOGGLE_MESSAGE_SIDEBAR_REQUEST
} from '../../redux/constants'
import { getAuthState, getThreadState } from '../../redux/selectors'
import { PaymentAlert, PriorityDialog, ServicesDialog, AssignTeamModal } from '..'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { MessageType, MessageUser, UserRoles } from '../../types/messages'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../hooks/featureFlags'

type ThreadHeaderProps = {
  message: MessageType
  showInfoModal: () => void
  toggleDrawer: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      height: 90
    }
  },
  info: {
    position: 'absolute',
    top: '20px',
    right: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hamburger: {
    cursor: 'pointer',
    marginLeft: 10
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6)
    }
  },
  buttonWrapper: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 10
    }
  },
  backBtn: {
    color: 'white'
  },
  headerWrapper: {
    display: 'flex',
    height: 80
  },
  avatarView: {
    width: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 60
    }
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  nameView: {
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  paidBadge: {
    marginLeft: 10
  },
  username: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      width: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  email: {
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      width: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  profilePicContainer: {
    display: 'flex'
  },
  teamMemberProfilePic: {
    marginRight: 10,
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main
  },
  moreBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      color: 'white',
      position: 'absolute',
      right: 10
    }
  }
}))

export function ThreadHeader({ message, showInfoModal, toggleDrawer }: ThreadHeaderProps): ReactElement {
  const classes = useStyles()
  const route = useRouteMatch()
  const dispatch = useDispatch()
  const { modal, messageId } = useParams<{ modal?: string; messageId: string }>()

  const { paidSuccess, updatedPriority } = useSelector(getThreadState)
  const { currentUser } = useSelector(getAuthState)
  const { customer, thread } = useInfoByMessageId()

  const [showPaidModal, setShowPaidModal] = useState(false)
  const [showPriorityModal, setShowPriorityModal] = useState(false)
  const [showServicesModal, setShowServicesModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showAssignTeamModal, setShowAssignTeamModal] = useState(false)
  const [teamMembersProfilePic, setTeamMembersProfilePic] = useState<MessageUser[] | undefined>([])

  const isCustomerSuccessLead = currentUser && currentUser.role?.slug === UserRoles.CUSTOMER_SUCCESS_LEAD
  const priority = customer && customer.user_priority

  const isAssignPritoryEnabled = useFeatureToggle(FeatureFlag.ASSIGN_PRIORITY)
  const isMarkAsPaidEnabled = useFeatureToggle(FeatureFlag.MARK_AS_PAID)
  const isAssignTeamEnabled = useFeatureToggle(FeatureFlag.ASSIGN_TEAM)

  useEffect(() => {
    if (paidSuccess) {
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: 'User has been marked as a paid user', severity: 'info' }
      })
      dispatch({ type: RESET_PAID_SUCCESS_STATUS })
    }
  }, [paidSuccess])

  useEffect(() => {
    if (updatedPriority) {
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: `Priority of ${customer?.user_name} is updated`, severity: 'info' }
      })
      dispatch({ type: RESET_UPDATE_PRIORITY_SUCCESS_STATUS })
    }
  }, [updatedPriority])

  useEffect(() => {
    const updatedProfilePicList = thread?.message.group.filter(
      (member) => member.user_type !== 'tenant' && member.user_email !== 'support@perchpeek.com'
    )

    setTeamMembersProfilePic(updatedProfilePicList)
  }, [thread?.message.group.length])

  const toggleSidebar = () => {
    if (route.path.includes('/tasks')) {
      history.pushState({}, '', '/messages')
    } else {
      dispatch({ type: TOGGLE_MESSAGE_SIDEBAR_REQUEST })
    }
  }

  const markPaidFn = () => {
    dispatch({
      type: MARK_USER_PAID_REQUEST,
      payload: { userId: customer?.linked_user_id, messageId: message.message_id }
    })
    closeModal()
  }

  const openPaidModal = () => {
    setShowPaidModal(true)
    setAnchorEl(null)
  }

  const openPriorityModal = () => {
    setShowPriorityModal(true)
    setAnchorEl(null)
  }

  const openServicesModal = () => {
    history.pushState({}, '', `/messages/${messageId}/services`)
    setShowServicesModal(true)
    setAnchorEl(null)
  }

  const closeModal = () => {
    setShowPriorityModal(false)
    setShowServicesModal(false)
    setShowPaidModal(false)
    history.pushState({}, '', `/messages/${messageId}`)
  }

  const openMobileMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMobileMenu = () => {
    setAnchorEl(null)
  }

  const toggleInfo = () => {
    setAnchorEl(null)
    showInfoModal()
  }

  const loadWholeThread = () => {
    dispatch({ type: FETCH_WHOLE_THREAD_REQUEST })
  }

  const toggleNotes = () => {
    setAnchorEl(null)
    toggleDrawer()
  }

  useEffect(() => {
    // Open the correct modal based on the route param
    const modalRouteMap: Record<string, () => void> = {
      info: toggleInfo,
      services: openServicesModal
    }

    if (modal && modalRouteMap[modal]) {
      modalRouteMap[modal]()
    }
  }, [])

  return (
    <div data-testid="thread-header-container" className={classes.container}>
      <div className={classes.buttonWrapper}>
        <IconButton size="small" className={classes.backBtn} onClick={toggleSidebar} data-testid="mobile-back-btn">
          <ArrowBack />
        </IconButton>
      </div>
      <div className={classes.headerWrapper}>
        <div className={classes.avatarView}>
          {customer && customer.user_profile_image ? (
            <Avatar aria-label="avatar" className={classes.avatar} src={customer.user_profile_image} />
          ) : (
            <Avatar aria-label="avatar" className={classes.avatar}>
              <Person />
            </Avatar>
          )}
        </div>
        <div className={classes.textWrapper}>
          <div className={classes.nameView}>
            <Typography variant="body1" color="textPrimary" className={classes.username}>
              {customer?.user_name}
            </Typography>
            {customer && customer.user_has_paid && (
              <Chip
                color="secondary"
                size="small"
                label="Paid"
                variant="outlined"
                className={classes.paidBadge}
                data-testid="paid-chip"
              />
            )}
          </div>
          <Typography variant="subtitle2" color="textSecondary" className={classes.email}>
            {customer?.user_email}
          </Typography>
        </div>
      </div>
      <div className={classes.info}>
        {isAssignTeamEnabled && isCustomerSuccessLead && (
          <Button onClick={() => setShowAssignTeamModal(true)} color="secondary" data-testid="team-members-btn">
            {teamMembersProfilePic && teamMembersProfilePic.length > 0 ? (
              <div className={classes.profilePicContainer}>
                {teamMembersProfilePic.map((member) => (
                  <Avatar
                    aria-label={`${member.user_name}'s profile pic`}
                    key={member.linked_user_id}
                    className={classes.teamMemberProfilePic}
                    src={member.user_profile_image}
                  />
                ))}
              </div>
            ) : (
              'Assign'
            )}{' '}
            Team
          </Button>
        )}
        {isMarkAsPaidEnabled && !customer?.user_has_paid && (
          <Button onClick={openPaidModal} color="secondary">
            Mark as Paid
          </Button>
        )}
        {isAssignPritoryEnabled && (
          <Button onClick={openPriorityModal} color="secondary">
            {priority ? 'Reassign' : 'Assign'} priority
          </Button>
        )}
        <Button onClick={openServicesModal} color="secondary">
          Services
        </Button>
        <Button onClick={toggleInfo} color="secondary">
          + Info
        </Button>
        <Button onClick={loadWholeThread} color="secondary" data-track-click="load-whole-thread">
          Load whole thread
        </Button>

        <div className={classes.hamburger}>
          <MenuOutlined color="secondary" onClick={toggleDrawer} aria-label="Hamburger button" />
        </div>
      </div>
      <IconButton
        aria-label="settings"
        className={classes.moreBtn}
        onClick={openMobileMenu}
        data-testid="mobile-menu-btn"
      >
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMobileMenu} data-testid="mobile-menu">
        {isMarkAsPaidEnabled && !customer?.user_has_paid && <MenuItem onClick={openPaidModal}>Mark As Paid</MenuItem>}
        {isAssignPritoryEnabled && (
          <MenuItem onClick={openPriorityModal}>{priority ? 'Reassign' : 'Assign'} priority</MenuItem>
        )}
        <MenuItem onClick={openServicesModal}>Services</MenuItem>
        <MenuItem onClick={toggleInfo} data-testid="mobile-info-btn">
          Info
        </MenuItem>
        <MenuItem onClick={loadWholeThread} data-track-click="load-whole-thread">
          Load whole thread
        </MenuItem>
        <MenuItem onClick={toggleNotes} data-testid="mobile-notes-btn">
          Notes
        </MenuItem>
      </Menu>

      <Divider variant="middle" />
      <AssignTeamModal
        open={showAssignTeamModal}
        close={() => setShowAssignTeamModal(false)}
        moverName={customer?.user_name || 'Mover'}
      />
      <PaymentAlert open={showPaidModal} handler={markPaidFn} onClose={closeModal} username={customer?.user_name} />
      {showPriorityModal && (
        <PriorityDialog
          open={showPriorityModal}
          onClose={closeModal}
          priority={priority}
          username={customer?.user_name}
          userId={customer?.linked_user_id}
          messageId={message.message_id}
        />
      )}
      {showServicesModal && (
        <ServicesDialog open={showServicesModal} onClose={closeModal} userId={customer?.linked_user_id} />
      )}
    </div>
  )
}
