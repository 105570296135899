import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'

import { ADD_BOOKINGS_REQUEST } from '../../redux/constants'
import { BookingModalDetailsType } from '../../types/bookings'
import { BookingModal } from '..'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'

type BookingModalProps = {
  open: boolean
  setOpen: () => void
  details: BookingModalDetailsType
}

type bookingDetailsToSend = {
  address: string
  agent_name: string
  booking_date: string
  booking_time: string
  contact_number: string
  note: string
  property_id?: string
  title: string
  type: string
  tz: string
  url: string
}

export const AddBooking = ({ open, setOpen, details }: BookingModalProps): ReactElement => {
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()

  const createBooking = (bookingDetails: bookingDetailsToSend) => {
    if (customer) {
      if (bookingDetails.type !== 'property_visits') delete bookingDetails.property_id
      dispatch({
        type: ADD_BOOKINGS_REQUEST,
        payload: { userId: customer.linked_user_id, bookingDetails }
      })
    }
  }
  return <BookingModal open={open} setOpen={setOpen} type="create" details={details} modalAction={createBooking} />
}
