import moment from 'moment'
import { ReactElement, useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, CircularProgress, Dialog, Container } from '@material-ui/core'
import {
  DELETE_CHAT_REQUEST,
  FETCH_PROPERTY_REQUEST,
  SET_REPLY_MESSAGE_DETAILS,
  UNLIST_PROPERTY_REQUEST
} from '../../redux/constants'
import { PropertyDetails, ThreadPropertyType } from '../../types/properties'
import { CardOptions, ConfirmationDialog, PropertyCard } from '..'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { usePropertyById } from '../../hooks/usePropertyById'
import { PropertyChat, PusherPropertyChat } from '../../types/messages'
import { PropertyEdit, PropertyEditProps } from '../PropertyEdit'
import { castPropertyDetailsToPropertyUpload } from '../../redux/reducer/property/utils'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../hooks/featureFlags'
import { useHistory } from 'react-router-dom'
import { getThreadState } from '../../redux/selectors'

type Props = {
  info: PusherPropertyChat | PropertyChat
  type: ThreadPropertyType
}

type ConfirmationDialog = {
  status: boolean
  onConfirm: () => void
  message: string
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  paper: {
    width: 300,
    height: 460,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  doubleClickBox: {
    flex: 1
  },
  thirdPartyIdContainer: {
    textAlign: 'center',
    padding: '50px'
  }
}))

export const ThreadPropertyCard = memo(({ info, type }: Props): ReactElement => {
  const { name, chat_id, chat, created_at, activity_feed_item } = info
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { customer } = useInfoByMessageId()
  const {
    user: {
      info: { linked_user_id }
    }
  } = useSelector(getThreadState)

  const propertyId = JSON.parse(chat).property_id
  const propertyDetail = usePropertyById(propertyId)

  const allowCardDeletion = useFeatureToggle(FeatureFlag.ALLOW_CARD_DELETION)
  const commentRecommendedPropertyChat = useFeatureToggle(FeatureFlag.COMMENT_PROPERTY_RECOMMENDATION_CHAT)

  const [property, setProperty] = useState<PropertyDetails | null>(null)
  const [show3rdPartyDialog, setShow3rdPartyDialog] = useState<boolean>(false)
  const [showEditProperty, setShowEditProperty] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<ConfirmationDialog>({
    status: false,
    onConfirm: () => null,
    message: ''
  })

  const defaultOptions: CardOptions[] = [
    ...(commentRecommendedPropertyChat
      ? [
          {
            name: 'Comment',
            onClickCallback: () =>
              history.push(
                `/manage?v2Url=/feed/${linked_user_id}/activity/${activity_feed_item?.activity_feed_item_id}`
              ),
            isDisabled: false
          }
        ]
      : [
          {
            name: 'Reply',
            onClickCallback: () => handleReply(),
            isDisabled: false
          }
        ]),
    {
      name: 'Edit',
      onClickCallback: () => setShowEditProperty(true),
      isDisabled: false
    },
    {
      name: 'Unlist',
      onClickCallback: () => confirmationDialogHandle('unlist'),
      isDisabled: Boolean(property?.status === 'unlisted')
    },
    {
      name: 'View Property',
      onClickCallback: undefined,
      isDisabled: false
    },
    {
      name: 'View third-party URL',
      onClickCallback: () => thirdPartyProperty(),
      isDisabled: Boolean(!property?.third_party_property_id)
    }
  ]

  const hoursPassed = moment().diff(moment(created_at), 'h')

  const deleteOption = {
    name: 'Delete',
    onClickCallback: () => confirmationDialogHandle('delete'),
    isDisabled: Boolean(hoursPassed > 24)
  }

  const cardOptions =
    type === ThreadPropertyType.SUGGESTED && allowCardDeletion ? [...defaultOptions, deleteOption] : defaultOptions

  useEffect(() => {
    if (propertyDetail) {
      setProperty(propertyDetail)
      setLoading(false)
    } else {
      dispatch({ type: FETCH_PROPERTY_REQUEST, payload: { propertyId } })
    }
  }, [propertyDetail])

  const unlistProperty = () => {
    if (customer) {
      dispatch({ type: UNLIST_PROPERTY_REQUEST, payload: { userId: customer.linked_user_id, propertyId } })
      if (property) {
        setProperty({ ...property, status: 'unlisted' })
      }
    }
  }

  const thirdPartyProperty = () => {
    if (property && property.third_party_property_id) {
      const thirdPartyId = property.third_party_property_id
      const hasProtocol = thirdPartyId.startsWith('https://') || thirdPartyId.startsWith('http://')
      if (thirdPartyId.includes('.')) {
        window.open(`${!hasProtocol ? 'https://' : ''}${property.third_party_property_id}`, '_blank')
      } else {
        setShow3rdPartyDialog(true)
      }
    }
  }

  const handleReply = () => {
    dispatch({
      type: SET_REPLY_MESSAGE_DETAILS,
      payload: {
        sender: name,
        chat: `${property?.address_line_1} ${property?.address_line_2 ?? ''}`,
        chatId: chat_id
      }
    })
  }

  const deleteMessage = () => {
    dispatch({ type: DELETE_CHAT_REQUEST, payload: { chatId: chat_id } })
  }

  const confirmationDialogHandle = (type: string) => {
    if (type === 'unlist') {
      setOpenConfirmationDialog({ status: true, onConfirm: unlistProperty, message: type })
    } else {
      setOpenConfirmationDialog({ status: true, onConfirm: deleteMessage, message: type })
    }
  }

  const handleOnClosePropertyEdit: PropertyEditProps['onClose'] = (updatedProperty) => {
    setShowEditProperty(false)

    if (updatedProperty) {
      dispatch({ type: FETCH_PROPERTY_REQUEST, payload: { propertyId: updatedProperty.id } })
    }
  }

  if (loading) {
    return (
      <Paper className={classes.paper} data-testid="progress-bar">
        <CircularProgress color="secondary" />
      </Paper>
    )
  }

  return (
    <div data-testid="thread-property-card-container" id={chat_id} className={classes.container}>
      {type === 'suggested' && <div className={classes.doubleClickBox} onDoubleClick={handleReply} />}

      {property && <PropertyCard property={property} cardOptions={cardOptions} info={info} type={type} />}

      {type === 'interested' && <div className={classes.doubleClickBox} onDoubleClick={handleReply} />}

      {show3rdPartyDialog && (
        <Dialog open={show3rdPartyDialog} onClose={() => setShow3rdPartyDialog(false)}>
          <Container className={classes.thirdPartyIdContainer}>
            <Typography variant="h6">Third-party ID: {property?.third_party_property_id}</Typography>
          </Container>
        </Dialog>
      )}

      {openConfirmationDialog.status && (
        <ConfirmationDialog
          open={openConfirmationDialog.status}
          setOpen={() => setOpenConfirmationDialog({ status: false, onConfirm: () => null, message: '' })}
          message={`Are you sure you want to ${openConfirmationDialog.message} this?`}
          onConfirm={openConfirmationDialog.onConfirm}
          trackingContext={`mark-property-${openConfirmationDialog.message}`}
        />
      )}

      {showEditProperty && property && (
        <PropertyEdit
          open={showEditProperty}
          property={castPropertyDetailsToPropertyUpload(property)}
          onClose={handleOnClosePropertyEdit}
        />
      )}
    </div>
  )
})
