import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon, TextareaAutosize, Typography } from '@material-ui/core'
import { getNoteState } from '../../redux/selectors'
import { Note, Loader } from '..'
import {
  CREATE_NOTE_REQUEST,
  FETCH_NOTES_REQUEST,
  FETCH_MORE_NOTES_REQUEST,
  RESET_API_ERROR,
  DELETE_NOTE_REQUEST,
  PIN_NOTE_REQUEST,
  UNPIN_NOTE_REQUEST,
  UPDATE_NOTE_REQUEST,
  SHOW_FEEDBACK
} from '../../redux/constants'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import type { Note as NoteType } from '../../types/notes'
import InfiniteScroll from 'react-infinite-scroller'

const useStyles = makeStyles((theme) => ({
  listSection: {
    padding: 15,
    height: 'calc(100% - 150px)',
    overflowY: 'auto',
    position: 'relative'
  },
  loadingOverlay: {
    backgroundColor: 'rgb(0 0 0 / 20%)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  },
  noteInputWrapper: {
    margin: 15,
    display: 'flex',
    alignItems: 'center'
  },
  noteInput: {
    minHeight: '36px',
    width: '90%',
    padding: '17px 10px 3px 10px',
    resize: 'none',
    fontFamily: 'Roboto',
    fontSize: 16,
    borderRadius: 5,
    overflow: 'auto !important'
  },
  emptyNoteWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noteBtn: {
    marginLeft: 10,
    width: 100,
    height: 50
  },
  failedScreen: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reloadButton: {
    marginTop: 20,
    backgroundColor: '#2983E2',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#2983E2'
    }
  }
}))

export function Notes(): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const {
    activeUserId,
    fetchNotesRequested,
    fetchMoreNotesRequested,
    fetchNotesFailed,
    failedNoteApi,
    notes,
    notesUpdationRequested,
    currentPage,
    lastPage
  } = useSelector(getNoteState)
  const [noteText, setNoteText] = useState('')

  const userId = customer?.linked_user_id

  useEffect(() => {
    fetchNotes()
  }, [])

  useEffect(() => {
    if (failedNoteApi) {
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: "We couldn't process your request, please try again", severity: 'error' }
      })
      dispatch({ type: RESET_API_ERROR })
    }
  }, [failedNoteApi])

  const fetchNotes = () => {
    if (customer && customer?.linked_user_id !== activeUserId) {
      dispatch({ type: FETCH_NOTES_REQUEST, payload: { userId: customer.linked_user_id } })
    }
  }

  const fetchMoreNotes = () => {
    if (!fetchMoreNotesRequested) {
      dispatch({ type: FETCH_MORE_NOTES_REQUEST, payload: { userId, currentPage } })
    }
  }

  const createNote = () => {
    if (customer && noteText) {
      dispatch({ type: CREATE_NOTE_REQUEST, payload: { userId, note: noteText } })
      setNoteText('')
    }
  }

  const handlePinNote = (isPinned: boolean, noteId: string) => {
    const action = isPinned ? UNPIN_NOTE_REQUEST : PIN_NOTE_REQUEST

    dispatch({ type: action, payload: { userId, noteId } })
  }

  const handleUpdateNote = (noteId: string, editedNote: string) => {
    dispatch({ type: UPDATE_NOTE_REQUEST, payload: { userId, noteId, newNoteText: editedNote } })
  }

  const handleDeleteNote = (noteId: string) => {
    dispatch({ type: DELETE_NOTE_REQUEST, payload: { userId, noteId } })
  }

  const renderNote = (note: NoteType) => (
    <Note
      key={note.support_note_id}
      noteId={note.support_note_id}
      sentBy={note.added_by}
      noteText={note.note}
      date={note.updated_at}
      isPinned={note.type === 'pinned'}
      isEdited={note.updated_at !== note.created_at}
      handlePinNote={handlePinNote}
      handleUpdateNote={handleUpdateNote}
      handleDeleteNote={handleDeleteNote}
    />
  )

  if (fetchNotesRequested) return <Loader />

  if (fetchNotesFailed) {
    return (
      <div data-testid="failed-fetch-container" className={classes.failedScreen}>
        <Typography variant={'h5'} style={{ fontWeight: 500 }}>
          Failed to fetch notes.
        </Typography>
        <Button variant="contained" className={classes.reloadButton} onClick={fetchNotes}>
          Retry
        </Button>
      </div>
    )
  }

  return (
    <>
      <div data-testid="notes-container" className={classes.listSection}>
        {notesUpdationRequested && <div data-testid="loading-overlay" className={classes.loadingOverlay} />}
        {notes.length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchMoreNotes()}
            hasMore={currentPage < lastPage}
            initialLoad={false}
            element={'div'}
            useWindow={false}
          >
            {notes.map((note) => renderNote(note))}
          </InfiniteScroll>
        )}
        {!notes.length && (
          <div className={classes.emptyNoteWrapper}>
            <Typography data-testid="no-notes" variant="subtitle1">
              No notes have been added
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.noteInputWrapper}>
        <TextareaAutosize
          data-testid="add-note-input"
          className={classes.noteInput}
          value={noteText}
          onChange={(e) => {
            setNoteText(e.target.value)
          }}
          maxRows={5}
          placeholder="Add note..."
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (!e.shiftKey) {
                createNote()
                e.preventDefault()
              }
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.noteBtn}
          endIcon={<Icon>send</Icon>}
          onClick={createNote}
          data-testid="add-note-button"
          data-traking="add-note"
        >
          Add
        </Button>
      </div>
    </>
  )
}
