import { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, IconButton, Tabs, Tab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFeatureToggle } from '@flopflip/react-broadcast'

import { FeatureFlag } from '../../hooks/featureFlags'
import { Notes, Bookings, Documents } from '../'

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: '100%',
    width: 600,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '60px'
  },
  closeBtn: {
    position: 'absolute',
    right: '10px'
  }
}))

type SidebarDrawerProps = {
  open: boolean
  toggleDrawer: () => void
}

function a11yProps(index: number) {
  return {
    id: `Drawer-tab-${index}`,
    'aria-controls': `Drawer-tab-${index}`
  }
}

export function SidebarDrawer({ open, toggleDrawer }: SidebarDrawerProps): ReactElement {
  const classes = useStyles()
  const [value, setValue] = useState<number>(0)
  const isDocumentsTabEnabled = useFeatureToggle(FeatureFlag.CHAT_DOCUMENTS_TAB)

  return (
    <Drawer
      data-testid="sidebar-drawer"
      variant="temporary"
      anchor="right"
      open={open}
      onClose={toggleDrawer}
      classes={{ paper: classes.drawerPaper }}
      PaperProps={{
        'aria-roledescription': 'content'
      }}
    >
      <div className={classes.drawerHeader}>
        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={(e, tab) => setValue(tab)}>
          <Tab label="Notes" data-testid="notes" {...a11yProps(0)} />
          <Tab label="Appointments" data-testid="appointments" {...a11yProps(1)} />
          {isDocumentsTabEnabled && <Tab label="Documents" data-testid="documents" {...a11yProps(2)} />}
        </Tabs>
        <IconButton onClick={toggleDrawer} className={classes.closeBtn} data-testid="close-btn">
          <CloseIcon />
        </IconButton>
      </div>
      {value === 0 && <Notes />}
      {value === 1 && <Bookings />}
      {value === 2 && <Documents />}
    </Drawer>
  )
}
