import { TFlagVariation } from '@flopflip/types'

export enum FeatureFlag {
  EXAMPLE_FLAG = 'example',
  ALLOW_CARD_DELETION = 'allowCardDeletion',
  CREATE_CONTENT_CARDS = 'createContentCards',
  SELECT_APPOINTMENT_TYPE = 'selectAppointmentType',
  REPLY_DOCUMENT_CHAT = 'replyDocumentChat',
  USER_PACKAGE_SELECTION = 'userPackageSelection',
  CREATE_OFF_LISTED_PROPERTY = 'createOffListedProperties',
  COMMENT_PROPERTY_RECOMMENDATION_CHAT = 'commentPropertyRecommendationChat',
  CHAT_DOCUMENTS_TAB = 'chatDocumentsTab',
  ASSIGN_PRIORITY = 'assignPriority',
  MARK_AS_PAID = 'markedAsPaid',
  ASSIGN_TEAM = 'assignTeam',
  RECOMMENDATIONS_TAB = 'recommendationsTab',
  RECOMMEND_SINGLE_PROPERTY = 'recommendSingleProperty',
  UPLOAD_DOCUMENT = 'uploadDocument'
}

export interface FeatureFlagDetails {
  flag: string
  default: TFlagVariation
}

export const allFeatureFlags = Object.values(FeatureFlag)
