import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Message, AssignmentTurnedIn, ExitToApp, Menu } from '@material-ui/icons'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { LOGOUT_USER_REQUEST } from '../../redux/constants'
import { getAuthState } from '../../redux/selectors'
import { UserRoles } from '../../types/messages'

const useStyles = makeStyles((theme) => ({
  mobileHeader: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 80,
      backgroundColor: theme.palette.primary.main,
      position: 'fixed',
      zIndex: 2
    }
  },
  menuBtn: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 10
    }
  },
  title: {
    fontSize: 16,
    color: 'white'
  },
  menuDrawer: {
    height: '100%',
    width: 250,
    backgroundColor: theme.palette.primary.dark
  },
  menuWrapper: {
    marginTop: 100
  },
  menuItem: {
    color: 'white'
  },
  hamburger: {
    color: 'white'
  }
}))

type HeaderProps = {
  title: string
}

export function MobileHeader({ title }: HeaderProps): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [menuOpen, setMenuOpen] = useState(false)
  const { currentUser } = useSelector(getAuthState)

  const isCustomerSuccessLead = currentUser && currentUser.role?.slug === UserRoles.CUSTOMER_SUCCESS_LEAD

  const menuItemClicked = (itemText: string) => {
    if (itemText === 'logout') {
      dispatch({ type: LOGOUT_USER_REQUEST })
      localStorage.clear()
      history.push('/login')
    } else {
      setMenuOpen(false)
      history.push(`/${itemText}`)
    }
  }

  const onHelp = () => {
    setMenuOpen(false)
    window.openZendeskWidget()
  }

  return (
    <>
      <div className={classes.mobileHeader} data-testid="mobile-header-item">
        <IconButton aria-label="hamburger" className={classes.menuBtn} onClick={() => setMenuOpen(!menuOpen)}>
          <Menu className={classes.hamburger} />
        </IconButton>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Drawer
        data-testid="mobile-drawer"
        variant="temporary"
        anchor="left"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        classes={{
          paper: classes.menuDrawer
        }}
      >
        <div className={classes.menuWrapper}>
          <List>
            <ListItem button>
              <ListItemIcon className={classes.menuItem}>
                <Message />
              </ListItemIcon>
              <ListItemText
                data-testid="message-item"
                primary={'Messages'}
                className={classes.menuItem}
                onClick={() => menuItemClicked('messages')}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon className={classes.menuItem}>
                <AssignmentTurnedIn />
              </ListItemIcon>
              {isCustomerSuccessLead && (
                <ListItemText
                  data-testid="users-and-roles-item"
                  primary={'Users and Roles'}
                  className={classes.menuItem}
                  onClick={() => menuItemClicked('users')}
                />
              )}
            </ListItem>
            <ListItem button>
              <ListItemIcon className={classes.menuItem}>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText data-testid="help-item" primary={'Help'} className={classes.menuItem} onClick={onHelp} />
            </ListItem>
            <ListItem button>
              <ListItemIcon className={classes.menuItem}>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText
                data-testid="logout-item"
                primary={'Logout'}
                className={classes.menuItem}
                onClick={() => menuItemClicked('logout')}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  )
}
