import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { push } from 'connected-react-router'
import {
  FETCH_THREADS_REQUEST,
  FETCH_THREADS_SUCCESS,
  FETCH_THREADS_FAILURE,
  FETCH_MORE_THREADS_REQUEST,
  FETCH_MORE_THREADS_SUCCESS,
  FETCH_MORE_THREADS_FAILURE,
  SEND_NEW_CHAT_REQUEST,
  SEND_NEW_CHAT_SUCCESS,
  SEND_NEW_CHAT_FAILURE,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_FAILURE,
  GET_USER_STATUS_REQUEST,
  GET_USER_STATUS_SUCCESS,
  GET_USER_STATUS_FAILURE,
  ADD_PENDING_CHAT,
  MARK_USER_PAID_REQUEST,
  MARK_USER_PAID_SUCCESS,
  MARK_USER_PAID_FAILURE,
  SET_USER_PRIORITY_REQUEST,
  SET_USER_PRIORITY_SUCCESS,
  SET_USER_PRIORITY_FAILURE,
  GET_RECOMMENDATIONS_PROPERTIES_REQUEST,
  GET_RECOMMENDATIONS_PROPERTIES_SUCCESS,
  GET_RECOMMENDATIONS_PROPERTIES_FAILURE,
  FETCH_PROPERTY_SUCCESS,
  GET_USER_SERVICES_REQUEST,
  GET_USER_SERVICES_SUCCESS,
  GET_USER_SERVICES_FAILURE,
  UPDATE_USER_SERVICES_REQUEST,
  UPDATE_USER_SERVICES_SUCCESS,
  UPDATE_USER_SERVICES_FAILURE,
  FETCH_THREADS_TILL_PARENT_REQUEST,
  FETCH_THREADS_TILL_PARENT_SUCCESS,
  FETCH_THREADS_TILL_PARENT_FAILURE,
  FETCH_USER_DOCUMENT_REQUEST,
  FETCH_USER_DOCUMENT_SUCCESS,
  FETCH_USER_DOCUMENT_FAILURE,
  FETCH_WHOLE_THREAD_FAILURE,
  FETCH_WHOLE_THREAD_REQUEST,
  FETCH_WHOLE_THREAD_SUCCESS,
  SHOW_FEEDBACK,
  RESET_FETCH_THREADS_TILL_PARENT_STATUS,
  SEND_NEW_CHAT_DOCUMENT_REQUEST,
  SEND_NEW_CHAT_DOCUMENT_SUCCESS,
  SEND_NEW_CHAT_DOCUMENT_FAILURE,
  UPDATE_LAST_CHAT_REQUEST,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAILURE,
  ACTION_CHAT_SUCCESS,
  ACTION_CHAT_FAILURE,
  ACTION_CHAT_REQUEST
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'
import { getPropertiesFromResponse } from '../../reducer/thread/utils'
import { getAuthState, getIdFromPath } from '../../selectors'
import { ChatType } from '../../../types/messages'

const instance = new Api()

export function* fetchThreadsFn({ payload }: PayloadType): SagaIterator {
  const { messageId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchChats, token, messageId)

    if (response && response.data && (response.status === 200 || response.status === 401)) {
      const action = response.status === 200 ? { type: FETCH_THREADS_SUCCESS, payload: response.data } : push('/')
      yield put(action)
    } else {
      yield put({ type: FETCH_THREADS_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_THREADS_FAILURE })
  }
}

export function* fetchMoreThreadsFn({ payload }: PayloadType): SagaIterator {
  const { currentPage, messageId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchMoreChats, token, messageId, currentPage)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MORE_THREADS_SUCCESS, payload: { data: response.data, messageId } })
    } else {
      yield put({ type: FETCH_MORE_THREADS_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_MORE_THREADS_FAILURE })
  }
}

export function* fetchThreadsTillParentFn({ payload }: PayloadType): SagaIterator {
  const { chatId } = payload
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchChatsTillParent, token, messageId, chatId)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_THREADS_TILL_PARENT_SUCCESS, payload: { data: response.data.data, messageId } })
    } else {
      yield put({ type: FETCH_THREADS_TILL_PARENT_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
      yield put({ type: RESET_FETCH_THREADS_TILL_PARENT_STATUS })
    }
  } catch (err) {
    yield put({ type: FETCH_THREADS_TILL_PARENT_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    yield put({ type: RESET_FETCH_THREADS_TILL_PARENT_STATUS })
  }
}

export function* fetchWholeThreadFn(): SagaIterator {
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchWholeThread, token, messageId)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_WHOLE_THREAD_SUCCESS, payload: { messageId, data: response.data.data } })
    } else {
      yield put({ type: FETCH_WHOLE_THREAD_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: FETCH_WHOLE_THREAD_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try again.', severity: 'error' } })
  }
}

export function* sendNewDocumentFn({ payload }: PayloadType): SagaIterator {
  const { file, chat, resend, moverID } = payload
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.sendDocument, token, moverID, messageId, file)
    if (response && response.data && response.status === 201) {
      const newChat = { ...chat, chat_id: response.data.data.chat_id, name: file.file.name }

      yield put({
        type: SEND_NEW_CHAT_DOCUMENT_SUCCESS,
        payload: {
          data: response.data,
          messageId,
          chat: {
            ...newChat,
            type: ChatType.DOCUMENT,
            user: {
              ...chat.user,
              user_type: chat.sender_user_type
            }
          },
          resend
        }
      })
      yield put({
        type: UPDATE_LAST_CHAT_REQUEST,
        payload: { newChat, messageId, userPresentInThread: true }
      })
    } else {
      yield put({ type: SEND_NEW_CHAT_DOCUMENT_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: SEND_NEW_CHAT_DOCUMENT_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try again.', severity: 'error' } })
  }
}

export function* sendNewChatFn({ payload }: PayloadType): SagaIterator {
  const { chat, resend } = payload
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  yield put({ type: ADD_PENDING_CHAT, payload: { chat, messageId } })
  try {
    const response = yield call(instance.sendChat, token, messageId, chat.chat, chat.parent_id)
    if (response && response.data && response.status === 201) {
      const newChat = { ...chat, chat_id: response.data.data.chat_id }

      yield put({
        type: SEND_NEW_CHAT_SUCCESS,
        payload: {
          data: response.data,
          messageId,
          chat: {
            ...newChat,
            type: ChatType.TEXT, // we need a type to mark existing (in our store) chat as sent
            user: {
              ...chat.user,
              user_type: chat.sender_user_type // we need user_type to mark existing (in our store) chat as sent
            }
          },
          resend
        }
      })
      yield put({
        type: UPDATE_LAST_CHAT_REQUEST,
        payload: { newChat, messageId, userPresentInThread: true }
      })
    } else {
      yield put({ type: SEND_NEW_CHAT_FAILURE, payload: { chat, messageId } })
    }
  } catch (err) {
    yield put({ type: SEND_NEW_CHAT_FAILURE, payload: { chat, messageId } })
  }
}

export function* deleteChatFn({ payload }: PayloadType): SagaIterator {
  const { chatId } = payload
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.deleteMessage, token, messageId, chatId)
    if (response && response.status === 200 && response.data.message === 'Chat marked as deleted') {
      yield put({ type: DELETE_CHAT_SUCCESS, payload: { messageId, chatId } })
    } else {
      yield put({ type: DELETE_CHAT_FAILURE })
    }
  } catch (err) {
    yield put({ type: DELETE_CHAT_FAILURE })
  }
}

export function* actionChatFn({ payload }: PayloadType): SagaIterator {
  const { chatId, actioned } = payload
  const messageId = yield select(getIdFromPath)
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.actionChat, token, chatId, { actioned })
    if (response && response.data && response.status === 200) {
      yield put({
        type: ACTION_CHAT_SUCCESS,
        payload: { messageId, chat: response.data.data }
      })
    } else {
      yield put({ type: ACTION_CHAT_FAILURE })
    }
  } catch (err) {
    yield put({ type: ACTION_CHAT_FAILURE })
  }
}

export function* getUserStatusFn({ payload }: PayloadType): SagaIterator {
  const { userId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchUserInfo, userId, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: GET_USER_STATUS_SUCCESS, payload: { data: response.data.data, userId } })
    } else {
      yield put({ type: GET_USER_STATUS_FAILURE })
    }
  } catch (err) {
    yield put({ type: GET_USER_STATUS_FAILURE })
  }
}

export function* getUserServicesFn({ payload }: PayloadType): SagaIterator {
  const { userId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchUserServices, userId, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: GET_USER_SERVICES_SUCCESS, payload: { data: response.data.data } })
    } else {
      yield put({ type: GET_USER_SERVICES_FAILURE })
    }
  } catch (err) {
    yield put({ type: GET_USER_SERVICES_FAILURE })
  }
}

export function* updateUserServicesFn({ payload }: PayloadType): SagaIterator {
  const { userId, includedServices, links } = payload
  const { token } = yield select(getAuthState)
  const calls = []

  if (links && Object.keys(links).length > 0) {
    calls.push(call(instance.updateUserServicesURL, userId, token, { services: links }))
  }
  if (includedServices) {
    calls.push(call(instance.updateUserServices, userId, token, includedServices, 'included'))
  }

  try {
    const responses = yield all(calls)

    const successful = calls.length === responses.filter(({ status }: { status: number }) => status === 200).length

    if (successful) {
      yield put({ type: UPDATE_USER_SERVICES_SUCCESS })
    } else {
      yield put({ type: UPDATE_USER_SERVICES_FAILURE })
    }
  } catch (err) {
    yield put({ type: UPDATE_USER_SERVICES_FAILURE })
  }
}

export function* markUserPaidFn({ payload }: PayloadType): SagaIterator {
  const { userId, messageId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.markUserPaid, token, userId)
    if (response && response.data && response.status === 200) {
      yield put({ type: MARK_USER_PAID_SUCCESS, payload: { userId, messageId } })
    } else {
      yield put({ type: MARK_USER_PAID_FAILURE })
    }
  } catch (err) {
    yield put({ type: MARK_USER_PAID_FAILURE })
  }
}

export function* setUserPriorityFn({ payload }: PayloadType): SagaIterator {
  const { userId, messageId, priority } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.setPriority, token, userId, priority)
    if (response && response.data && response.status === 200) {
      yield put({ type: SET_USER_PRIORITY_SUCCESS, payload: { userId, messageId, priority } })
    } else {
      yield put({ type: SET_USER_PRIORITY_FAILURE })
    }
  } catch (err) {
    yield put({ type: SET_USER_PRIORITY_FAILURE })
  }
}

export function* getRecommendationsPropertiesFn({ payload }: PayloadType): SagaIterator {
  const { userId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchRecommendationsProperties, token, userId)
    if (response && response.data && response.status === 200) {
      const properties = response.data.data.recommendations
      const extractedProperties = getPropertiesFromResponse(properties)

      yield put({ type: GET_RECOMMENDATIONS_PROPERTIES_SUCCESS, payload: { userId, properties } })
      yield put({ type: FETCH_PROPERTY_SUCCESS, payload: { property: extractedProperties } })
    } else {
      yield put({ type: GET_RECOMMENDATIONS_PROPERTIES_FAILURE })
    }
  } catch (err) {
    yield put({ type: GET_RECOMMENDATIONS_PROPERTIES_FAILURE })
  }
}

export function* fetchUserDocumentFn({ payload }: PayloadType): SagaIterator {
  const { document_id } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchUserDocument, document_id, token)

    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_USER_DOCUMENT_SUCCESS })
      yield call(window.open, response.data.data.temporary_url, '_blank')
    } else {
      yield put({ type: FETCH_USER_DOCUMENT_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_USER_DOCUMENT_FAILURE })
  }
}

export function* createCardFn({ payload }: PayloadType): SagaIterator {
  const { customerId, card } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.createCard, customerId, token, card)
    if (response && response.data && response.status === 201) {
      yield put({ type: CREATE_CARD_SUCCESS, payload: { message: 'Content Sent' } })
    } else {
      const responseMessage = response.response?.data?.message
      const message = !!responseMessage ? responseMessage : 'Failed! Try again'
      yield put({ type: CREATE_CARD_FAILURE, payload: { message } })
    }
  } catch (err) {
    yield put({ type: CREATE_CARD_FAILURE, payload: { message: 'Failed! Try again' } })
  }
}

export default function* threadSaga(): Generator {
  yield all([
    takeEvery(FETCH_THREADS_REQUEST, fetchThreadsFn),
    takeEvery(FETCH_MORE_THREADS_REQUEST, fetchMoreThreadsFn),
    takeEvery(FETCH_THREADS_TILL_PARENT_REQUEST, fetchThreadsTillParentFn),
    takeEvery(FETCH_WHOLE_THREAD_REQUEST, fetchWholeThreadFn),
    takeEvery(SEND_NEW_CHAT_REQUEST, sendNewChatFn),
    takeEvery(DELETE_CHAT_REQUEST, deleteChatFn),
    takeEvery(GET_USER_STATUS_REQUEST, getUserStatusFn),
    takeEvery(GET_USER_SERVICES_REQUEST, getUserServicesFn),
    takeEvery(UPDATE_USER_SERVICES_REQUEST, updateUserServicesFn),
    takeEvery(MARK_USER_PAID_REQUEST, markUserPaidFn),
    takeEvery(SET_USER_PRIORITY_REQUEST, setUserPriorityFn),
    takeEvery(GET_RECOMMENDATIONS_PROPERTIES_REQUEST, getRecommendationsPropertiesFn),
    takeEvery(FETCH_USER_DOCUMENT_REQUEST, fetchUserDocumentFn),
    takeEvery(SEND_NEW_CHAT_DOCUMENT_REQUEST, sendNewDocumentFn),
    takeEvery(CREATE_CARD_REQUEST, createCardFn),
    takeEvery(ACTION_CHAT_REQUEST, actionChatFn)
  ])
}
