import { put, takeEvery, call, all } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'

const instance = new Api()

export function* loginUserFn({ payload }: PayloadType): SagaIterator {
  const { email, password } = payload
  try {
    const response = yield call(instance.login, email, password)
    // TODO: Login response status changed from 201 to 200 after the merge, once merge is deployed
    // to production can remove the check for 201
    if (response && response.data && (response.status === 201 || response.status === 200)) {
      yield put({
        type: LOGIN_USER_SUCCESS,
        payload: { token: response.data.data.token, userType: response.data.data.user_type }
      })
    } else {
      yield put({ type: LOGIN_USER_FAILURE })
    }
  } catch (err) {
    yield put({ type: LOGIN_USER_FAILURE })
  }
}

export function* fetchUserFn({ payload }: PayloadType): SagaIterator {
  const { token } = payload

  try {
    const response = yield call(instance.fetchUser, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_USER_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_USER_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_USER_FAILURE })
  }
}

export default function* authSaga(): Generator {
  yield all([takeEvery(LOGIN_USER_REQUEST, loginUserFn), takeEvery(FETCH_USER_REQUEST, fetchUserFn)])
}
