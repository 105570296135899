import moment from 'moment'
import { ReactElement, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, CircularProgress, Theme, IconButton, Menu, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import DocumentIcon from '@material-ui/icons/Description'
import { useFeatureToggle } from '@flopflip/react-broadcast'

import { getThreadState } from '../../redux/selectors'
import { FETCH_USER_DOCUMENT_REQUEST, DELETE_CHAT_REQUEST, SET_REPLY_MESSAGE_DETAILS } from '../../redux/constants'
import { FeatureFlag } from '../../hooks/featureFlags'
import { ChatStatus, DocumentChat as TDocumentChat } from '../../types/messages'
import { ConfirmationDialog, DeleteChat } from '..'

interface StyleProps {
  isTenant: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    margin: '10px 0',
    display: ({ isTenant }) => (isTenant ? 'initial' : 'flex'),
    flexDirection: ({ isTenant }) => (isTenant ? 'unset' : 'column'),
    alignItems: ({ isTenant }) => (isTenant ? 'initial' : 'flex-end')
  },
  paper: {
    width: '100%',
    maxWidth: 300,
    padding: '10px',
    position: 'relative',
    color: ({ isTenant }) => (isTenant ? theme.palette.primary.main : theme.palette.common.white),
    backgroundColor: ({ isTenant }) => (isTenant ? theme.palette.common.white : theme.palette.primary.main),
    '&:hover': {
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        '& $messageOptions': {
          visibility: 'visible'
        }
      }
    }
  },
  messageOptions: {
    color: ({ isTenant }) => (isTenant ? 'grey' : theme.palette.common.white),
    visibility: 'visible',
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden'
    },
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  date: {
    fontSize: '0.6em'
  }
}))

type Props = {
  isTenant: boolean
} & TDocumentChat

export function DocumentChat(info: Props): ReactElement {
  const { chat, chat_id, created_at, isTenant, name, sender_name, status } = info
  const classes = useStyles({ isTenant })
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isReplyEnabled = useFeatureToggle(FeatureFlag.REPLY_DOCUMENT_CHAT)

  const isDeleted = status === ChatStatus.DELETED

  const { documentFetching } = useSelector(getThreadState)

  const hoursPassed = moment().diff(moment(created_at), 'h')

  const { document_id, title } = JSON.parse(chat)

  useEffect(() => {
    !documentFetching && setLoading(false)
  }, [documentFetching])

  const fetchDocument = () => {
    setLoading(true)
    dispatch({
      type: FETCH_USER_DOCUMENT_REQUEST,
      payload: { document_id }
    })
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleReplyToMsg = () => {
    setAnchorEl(null)
    dispatch({
      type: SET_REPLY_MESSAGE_DETAILS,
      payload: { sender: sender_name || name, chat, chatId: chat_id }
    })
  }

  const deleteMessage = () => {
    setAnchorEl(null)
    dispatch({ type: DELETE_CHAT_REQUEST, payload: { chatId: (info as TDocumentChat).chat_id } })
  }

  return (
    <>
      <div className={classes.container} data-testid="tenant-document-chat">
        <Paper data-testid="chat-paper" className={classes.paper} onClick={fetchDocument}>
          {isDeleted ? (
            <DeleteChat type={isTenant ? 'tenant' : 'support'} />
          ) : (
            <>
              <DocumentIcon color={isTenant ? 'primary' : 'inherit'} />
              <>
                <IconButton
                  data-testid="document-chat-options-icon"
                  aria-label="message-options"
                  classes={{ root: classes.messageOptions }}
                  onClick={handleClick}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <Menu
                  data-testid="menu"
                  id="menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClick={(event: MouseEvent<HTMLElement>) => {
                    event.stopPropagation()
                  }}
                  onClose={() => setAnchorEl(null)}
                >
                  {isReplyEnabled && (
                    <MenuItem data-testid="document-reply-option" onClick={handleReplyToMsg}>
                      Reply
                    </MenuItem>
                  )}

                  {!isTenant && (
                    <MenuItem
                      data-testid="document-delete-option"
                      disabled={hoursPassed > 24}
                      onClick={() => setOpenConfirmationDialog(true)}
                    >
                      Delete
                    </MenuItem>
                  )}
                </Menu>
              </>
              <Typography variant="subtitle2" color={isTenant ? 'primary' : 'inherit'}>
                {title}
              </Typography>
              {loading && <CircularProgress data-testid="document-fetching-loader" size={20} color="secondary" />}
            </>
          )}
        </Paper>
        <Typography variant="subtitle2" color="textSecondary" className={classes.date}>
          {moment.utc(created_at).local().format('dddd, Do of MMMM, H:mm')}
        </Typography>
      </div>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={() => setOpenConfirmationDialog(false)}
        message="Are you sure you want to delete this message?"
        onConfirm={deleteMessage}
        trackingContext="chat-document-delete"
      />
    </>
  )
}
