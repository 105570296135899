import { ReactElement, useEffect, useState, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CssBaseline, TextField, Typography, Container, Box } from '@material-ui/core'
import { LOGIN_USER_REQUEST, FETCH_USER_REQUEST, LOGOUT_USER_REQUEST, SHOW_FEEDBACK } from '../../redux/constants'
import { getAuthState } from '../../redux/selectors'

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export function Login(): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { token, status, currentUser, userType, loginFailed } = useSelector(getAuthState)

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (token) {
      if (userType === 'va' || userType === 'support') {
        dispatch({ type: FETCH_USER_REQUEST, payload: { token } })
      } else {
        dispatch({ type: LOGOUT_USER_REQUEST })
        dispatch({
          type: SHOW_FEEDBACK,
          payload: { title: "You don't have access to view this page", severity: 'error' }
        })
        setLoading(false)
      }
    }
  }, [token, userType])

  useEffect(() => {
    if (loginFailed) {
      setLoading(false)
      dispatch({ type: LOGOUT_USER_REQUEST })
      dispatch({
        type: SHOW_FEEDBACK,
        payload: { title: 'Invalid username or password', severity: 'error' }
      })
    }
  }, [loginFailed])

  useEffect(() => {
    if (token && status === 'success' && currentUser) {
      localStorage.setItem('authToken', token)
      localStorage.setItem('userId', currentUser.user_id)
      history.push('/messages')
    }
  }, [token, status, currentUser])

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    dispatch({ type: LOGIN_USER_REQUEST, payload: { email, password } })
  }

  return (
    <Container className={classes.main} component="main" maxWidth="xs" data-testid="login-container">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitHandler}>
          <Box>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Box>
          <Box>
            <TextField
              id="password"
              margin="normal"
              name="password"
              label="Password"
              variant="outlined"
              fullWidth
              required
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </Box>
          <Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              className={classes.submit}
              data-testid="sing-in-btn"
              onClick={submitHandler}
            >
              {loading ? 'Loading...' : 'Sign In'}
            </Button>
          </Box>
        </form>
      </div>
    </Container>
  )
}
