import { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles, Typography, Dialog, Container } from '@material-ui/core'
import moment from 'moment'

import { timezones } from '../../../utils/timezones'
import { PropertyDetails, UserInfoTabsTitle } from '../../../types/properties'
import { MessageUser } from '../../../types/messages'
import { BookingModalDetailsType } from '../../../types/bookings'
import { GET_RECOMMENDATIONS_PROPERTIES_REQUEST, UNLIST_PROPERTY_REQUEST } from '../../../redux/constants'
import { AddBooking, EditBooking, ConfirmationDialog, PropertyCard, CardOptions } from '../..'
import { PropertyEdit, PropertyEditProps } from '../../PropertyEdit'
import { castPropertyDetailsToPropertyUpload } from '../../../redux/reducer/property/utils'

type InfoCardProps = {
  property: PropertyDetails
  details: {
    agent_name?: string
    booking_date?: string
    booking_time?: string
    booking_id?: string
    customer?: MessageUser
    contact_number?: string
    index: number
    status?: string
    tz?: string
    url?: string
  }
  setIdToRemove: Dispatch<SetStateAction<string>>
}

type ConfirmationDialogState = {
  status: boolean
  onConfirm: () => void
  message: string
}

const useStyles = makeStyles(() => ({
  thirdPartyIdContainer: {
    textAlign: 'center',
    padding: '50px'
  }
}))

export function UserInfoCards({ property, details, setIdToRemove }: InfoCardProps): ReactElement {
  const { address_line_1, address_line_2, property_id, status, third_party_property_id, title } = property
  const { agent_name, booking_date, booking_time, booking_id, contact_number, customer, index, tz, url } = details

  const classes = useStyles()
  const dispatch = useDispatch()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [openAddBooking, setOpenAddBooking] = useState<boolean>(false)
  const [openEditBooking, setOpenEditBooking] = useState<boolean>(false)
  const [showEditProperty, setShowEditProperty] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<ConfirmationDialogState>({
    status: false,
    onConfirm: () => null,
    message: ''
  })

  const [bookingDialogDetails, setBookingDialogDetails] = useState<BookingModalDetailsType>({
    agent_name: '',
    contact_number: '',
    url: '',
    property_id: '',
    address: '',
    tz: { selectValue: 'Europe/London', displayValue: '(GMT+01:00) London' },
    dateTime: moment(),
    booking_id: '',
    type: '',
    note: '',
    title: ''
  })

  const cardOptions: CardOptions[] = [
    {
      name: 'Edit',
      onClickCallback: () => setShowEditProperty(true),
      isDisabled: false
    },
    {
      name: 'Unlist',
      onClickCallback: () => confirmationDialogSubmit('unlisted'),
      isDisabled: Boolean(status === 'unlisted')
    },
    {
      name: 'View Property',
      onClickCallback: undefined,
      isDisabled: false
    },
    {
      name: 'View third-party URL',
      onClickCallback: () => thirdPartyProperty(),
      isDisabled: Boolean(!third_party_property_id)
    },
    {
      name: 'Convert to booking',
      onClickCallback: () => convertToBooking(index > 2 ? 'booking' : 'property'),
      isDisabled: false
    }
  ]

  const thirdPartyProperty = () => {
    if (third_party_property_id) {
      const hasProtocol = third_party_property_id.startsWith('http')
      if (third_party_property_id.includes('.')) {
        window.open(`${!hasProtocol ? 'https://' : ''}${third_party_property_id}`, '_blank')
      } else {
        setShowDialog(true)
      }
    }
  }

  const unlistProperty = () => {
    dispatch({
      type: UNLIST_PROPERTY_REQUEST,
      payload: {
        userId: customer?.linked_user_id,
        propertyId: property_id,
        userInfo: {
          unlistInUserInfo: true,
          type: UserInfoTabsTitle.RECOMMENDATIONS
        }
      }
    })
    setShowDialog(false)
  }

  const confirmationDialogSubmit = (type: string) => {
    if (type === 'unlisted') {
      setOpenConfirmationDialog({ status: true, onConfirm: unlistProperty, message: type })
    }
  }

  const convertToBooking = (conversionType: string) => {
    const existingTimezone = timezones.find((zone) => zone.selectValue === tz)
    const bookingTime = booking_time?.includes(':')
      ? booking_time
      : `${booking_time?.substring(0, 2)}:${booking_time?.substring(2)}`

    setBookingDialogDetails({
      address: address_line_1 ? `${address_line_1} ${address_line_2 ? address_line_2 : ''}` : title,
      agent_name: agent_name || '',
      booking_id: booking_id || '',
      contact_number: contact_number || '',
      dateTime: booking_date ? `${booking_date} ${bookingTime}` : moment(),
      note: '',
      property_id,
      title: title || '',
      type: 'property_visits',
      tz: existingTimezone || { selectValue: 'Europe/London', displayValue: '(GMT+01:00) London' },
      url: url || ''
    })
    if (conversionType === 'booking') {
      setOpenEditBooking(true)
    } else {
      setOpenAddBooking(true)
    }
    setIdToRemove(booking_id || property_id || '')
  }

  const handleOnClosePropertyEdit: PropertyEditProps['onClose'] = (updatedProperty) => {
    setShowEditProperty(false)

    if (updatedProperty) {
      dispatch({ type: GET_RECOMMENDATIONS_PROPERTIES_REQUEST, payload: { userId: customer?.linked_user_id } })
    }
  }

  return (
    <>
      <div data-testid="user-info-card-container">
        <PropertyCard property={property} cardOptions={cardOptions} />
      </div>

      {showDialog && (
        <Dialog data-testid="third-party-id-dialog" open={showDialog} onClose={() => setShowDialog(false)}>
          <Container className={classes.thirdPartyIdContainer}>
            <Typography variant="h6">Third-party ID: {third_party_property_id}</Typography>
          </Container>
        </Dialog>
      )}

      {openConfirmationDialog.status && (
        <ConfirmationDialog
          open={openConfirmationDialog.status}
          setOpen={() => setOpenConfirmationDialog({ status: false, onConfirm: () => null, message: '' })}
          message={`Are you sure you want to mark this as ${openConfirmationDialog.message}?`}
          onConfirm={openConfirmationDialog.onConfirm}
          trackingContext={`mark-property-${openConfirmationDialog.message}`}
        />
      )}

      {openAddBooking && (
        <AddBooking open={openAddBooking} setOpen={() => setOpenAddBooking(false)} details={bookingDialogDetails} />
      )}

      {openEditBooking && (
        <EditBooking
          open={openEditBooking}
          setOpen={() => setOpenEditBooking(false)}
          type={'convert'}
          details={bookingDialogDetails}
        />
      )}

      {property && showEditProperty && (
        <PropertyEdit
          open={showEditProperty}
          property={castPropertyDetailsToPropertyUpload(property)}
          onClose={handleOnClosePropertyEdit}
        />
      )}
    </>
  )
}
